import React, { Component, ReactNode } from 'react';
import ErrorContainer from './styled';
import { ButtonFilled } from 'components/ui/Button';
import { ReactComponent as LogoSVGDesktop } from 'assets/Icon/logo.svg';
import IconSvg from 'components/ui/IconSvg';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    const handleLogoutClick = () => {
      window.location.reload();
    };

    if (hasError) {
      return (
        <ErrorContainer>
          <div className="content">
            <IconSvg className="svgMobile"  width={290} icon={LogoSVGDesktop} />
            <div className="box">
              <p>Something went wrong...</p>
              <ButtonFilled onClick={handleLogoutClick}>Reload Page</ButtonFilled>
            </div>
          </div>
        </ErrorContainer>
      );
    }
    return children;
  }
}

export default ErrorBoundary;

import * as styledComponents from 'styled-components';

type DevicesSize = {
  xsmall: number;
  medium: number;
  large: number;
  xxlarge: number;
};

const deviceSize = {
  xsmall: 350,
  medium: 768,
  large: 992,
  xxlarge: 1440,
};

type Devices = {
  xsmall: string;
  medium: string;
  large: string;
  xxlarge: string;
};

const devices = {
  xsmall: `(min-width: ${deviceSize.xsmall}px)`,
  medium: `(min-width: ${deviceSize.medium}px)`,
  large: `(min-width: ${deviceSize.large}px)`,
  xxlarge: `(min-width: ${deviceSize.xxlarge}px)`,
};

type Dimensions = {
  contentMaxWidthBig: number;
  headerHeightMobile: number;
  headerHeight: number;
  spacingBase10: number;
  spacingBase16: number;
  basePadding: number;
};

const dimensions = {
  contentMaxWidthBig: 1440,
  headerHeightMobile: 46,
  headerHeight: 72,
  spacingBase10: 10,
  spacingBase16: 16,
  basePadding: 24,
};

type FontSize = {
  smallBase: string;
  label: string;
  mediumBase: string;
  base: string;
  titleSmall: string;
  titleMedium: string;
  titleBig: string;
};

const fontSize = {
  smallBase: '10px',
  label: '12px',
  mediumBase: '14px',
  base: '16px',
  titleSmall: '20px',
  titleMedium: '24px',
  titleBig: '40px',
};

type FontWeight = {
  light: number;
  medium: number;
  heavy: number;
};

const fontWeight = {
  light: 300,
  medium: 500,
  heavy: 700,
};

type Palette = {
  primary: string;
  secondary: string;
  dark: string;
  darkMedium: string;
  gray: string;
  grayMedium: string;
  grayMedium2: string;
  grayMedium3: string;
  grayLight: string;
  grayLight2: string;
  light: string;
  white: string;
  success: string;
  error: string;
  warning: string;
  [prop: string]: string;
};

const palette = {
  dark: '#000',
  darkMedium: '#15191E',
  primary: '#52D381',

  secondary: '#4642FF',
  gray: '#535D70',
  grayMedium: '#A7A8B2',
  grayMedium2: '#A5B0C2',
  grayMedium3: '#979797',
  grayLight: '#DADBE1',
  grayLight2: '#D9DDE4',
  light: '#D9DDE4',
  white: '#EFEFEF',
  success: '#5DE5CD',
  error: '#FF6782',
  warning: '#FFE577',
};

type UI = {
  transition(property: string, multiplier?: number): string;
};

const ui = {
  transition: (prop: string, multiplier = 1) =>
    `${prop} ${multiplier * 200}ms ease-in`,
};

type ZIndexes = {
  header: number;
};

const zIndexes = {
  header: 600,
};

export interface ThemeInterface {
  deviceSize: DevicesSize;
  devices: Devices;
  dimensions: Dimensions;
  fontSize: FontSize;
  fontWeight: FontWeight;
  palette: Palette;
  ui: UI;
  zIndexes: ZIndexes;
}

export const theme: ThemeInterface = {
  deviceSize,
  devices,
  dimensions,
  fontSize,
  fontWeight,
  palette,
  ui,
  zIndexes,
};

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  useTheme,
} = styledComponents;

export { css, createGlobalStyle, keyframes, ThemeProvider, useTheme };
export default styled;

import React, { useState, useEffect } from 'react';
import { useTournament } from 'context/TournamentContext';

import { useUser } from 'api/userService';
import { logout } from 'api/authService';
import Cookies from 'js-cookie';
import { LANDING } from 'constants/routes';
import IconSvg from 'components/ui/IconSvg';
import BannerNav from 'components/ui/BannerNav';
import Menu from 'components/ui/Menu';

import { ReactComponent as LogoSVGDesktop } from 'assets/Icon/logo.svg';
import { ReactComponent as LogoSVGMobile } from 'assets/Icon/mobile_logo.svg';

import {
  Wrap,
  MobileWrap,
  BtnLogo,
  BtnLogout,
  IconLogOut,
  Avatar,
  AvatarIcon,
  AvatarName,
  Banner,
  Content,
  InfoSponsor,

  DesktopWrap,
  ColLeft,
  ColRight,
  ContentDesktop,
} from './styled';

const Nav = (props) => {
  const { showBanner, showAvatar } = props;
  const username = localStorage.getItem('username') ?? Cookies.get('username');
  const { isLoggedIn } = useUser();

  let buttonLogout;

  const handleLogoutClick = () => {
    logout();
  };

  if (isLoggedIn) {
    buttonLogout = <>Logout</>
  } else {
    buttonLogout = <>Log In</>;
  }

  return (
    <Wrap>
      <MobileWrap>
        <Content>
          <BtnLogo to={LANDING}>
            <IconSvg className="svgMobile"  width={60} icon={LogoSVGMobile} />
          </BtnLogo>

          {showAvatar &&
            <Avatar>
              {isLoggedIn &&
                <>
                  <AvatarIcon></AvatarIcon>
                  <AvatarName>
                    {username}
                  </AvatarName>
                </>
              }
            </Avatar>
          }

          <BtnLogout onClick={handleLogoutClick} to={LANDING}>
            {buttonLogout}
            <IconLogOut />
          </BtnLogout>

        </Content>
        {showBanner &&
          <Banner>
            <InfoSponsor>Sponsored by:</InfoSponsor>
            <BannerNav />
          </Banner>
        }
        <Menu />
      </MobileWrap>

      <DesktopWrap>
        <ColLeft>
          <ContentDesktop>
            <BtnLogo to={LANDING}>
              <IconSvg className="svgDesktop"  width={150} icon={LogoSVGDesktop} />
            </BtnLogo>

            {showAvatar &&
              <Avatar className="avatarDesktop">
                {isLoggedIn &&
                  <>
                    <AvatarIcon></AvatarIcon>
                    <AvatarName>
                      {username}
                    </AvatarName>
                  </>
                }
              </Avatar>
            }
          </ContentDesktop>

          {showBanner &&
            <Banner className="desktopBanner">
              <InfoSponsor>Sponsored by:</InfoSponsor>
              <BannerNav />
            </Banner>
          }
          <Menu />

        </ColLeft>
        <ColRight>
          <BtnLogout onClick={handleLogoutClick} to={LANDING}>
            {buttonLogout}
            <IconLogOut />
          </BtnLogout>
        </ColRight>
      </DesktopWrap>
    </Wrap>
  );
};

export default Nav;

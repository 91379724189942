import React, { useState, useEffect } from 'react';
import { ButtonFilled } from 'components/ui/Button';
import { Link } from 'react-router-dom';
import { GAME, RESULTS } from 'constants/routes';
import { ReactComponent as TournamentSVG } from 'assets/intro.svg';
import IconSvg from 'components/ui/IconSvg';
import { getMyTournaments, getPublicTournaments, getPastTournaments } from 'api/tournamentService';

import {
  Wrap,
  Ul,
  Li,
  Box,
  DetailInfo,
  ColLeft,
  ColRight,
  Row,
  BoxPhoto,
  BoxContent,
  Title,
  Status,
  Date,
  TournamentPhoto,
  BoxStatus,
  StatusOnline,
  TagText,
  BoxTag,
  Info,
  SmallText,
  Tab,
  ButtonTab,
  LoadAnime,
  LoadWrap,
} from './styled';

type TournamentStatus = 'in_progress' | 'finished' | 'planned';

interface TournamentAttributes {
  status: TournamentStatus;
  start_at: string;
  end_at: string;
  is_public: boolean;
  name: string;
  amount_start: number;
  reward_pool: number | null;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  reward_pool_currency: string | null;
  contact_after_win: string | null;
  trade_type: 'Spot' | 'Margin' | null;
  leverage: string[] | null;
  allow_anonymous_users: boolean | null;
  hash: string | null;
  users_limit: number | null;
}

interface Tournament {
  id: number;
  attributes: TournamentAttributes;
}

interface TournamentResponse {
  data: Tournament[];
}

const ListTournaments = () => {
  const [activeTab, setActiveTab] = useState("tab2");
  const handleTab1 = () => { setActiveTab("tab1"); };
  const handleTab2 = () => { setActiveTab("tab2"); };
  const handleTab3 = () => { setActiveTab("tab3"); };
  const [loading, setLoading] = useState(false);
  const [tournaments, setTournaments] = useState<Tournament[]>([]);

  useEffect(() => {
    const fetchTournaments = async () => {
      setLoading(true);
      try {
        let response: TournamentResponse;
        switch (activeTab) {
          case "tab1":
            response = await getMyTournaments();
            break;
          case "tab2":
            response = await getPublicTournaments();
            break;
          case "tab3":
            response = await getPastTournaments();
            break;
          default:
            response = { data: [] };
        }
        setTournaments(response.data || []);
      } catch (error) {
        console.error('Error fetching tournaments:', error);
        setTournaments([]);
      } finally {
        setLoading(false);
      }
    };

    fetchTournaments();
  }, [activeTab]);

  const getTabTitle = () => {
    switch (activeTab) {
      case "tab1":
        return "My Tournaments";
      case "tab2":
        return "Available Tournaments";
      case "tab3":
        return "Past Tournaments";
      default:
        return "";
    }
  };

  /**
   * Formats a date string into a localized format.
   * @param dateString - ISO date string to format
   * @returns Formatted date string or error message if invalid
   */
  const formatDate = (dateString: string): string => {
    if (!dateString?.trim()) {
      console.warn('Empty or invalid date string provided');
      return 'No date';
    }

    try {
      // Use the global Date object explicitly
      const date = new globalThis.Date(dateString);

      // Check if date is valid
      if (date.toString() === 'Invalid Date') {
        console.warn(`Invalid date string provided: ${dateString}`);
        return 'Invalid date';
      }

      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      });
    } catch (error) {
      console.error('Date formatting error:', {
        error,
        dateString,
        errorMessage: error instanceof Error ? error.message : 'Unknown error'
      });
      return 'Invalid date';
    }
  };

  return (
    <Wrap>
      <Tab>
        <ButtonTab className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>My</ButtonTab>
        <ButtonTab className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>All</ButtonTab>
        <ButtonTab className={activeTab === "tab3" ? "active" : ""} onClick={handleTab3}>Past</ButtonTab>
      </Tab>

      <SmallText>{getTabTitle()}</SmallText>

      {loading ? (
        <LoadWrap>
          <LoadAnime />
        </LoadWrap>
      ) : (
        <Ul>
          {tournaments.map((tournament) => (
            <Li key={tournament.id}>
              <DetailInfo>
                {tournament.attributes.trade_type || 'Standard'} Tournament
              </DetailInfo>
              <Box>
                <ColLeft>
                  <Row>
                    <BoxPhoto>
                      <TournamentPhoto>
                        <IconSvg icon={TournamentSVG} />
                      </TournamentPhoto>
                      <Status className={tournament.attributes.status === 'finished' ? '' : 'active'}>
                        Live
                        {/*
                          {tournament.attributes.status === 'in_progress' ? 'Live' : tournament.attributes.status}
                        */}
                      </Status>
                    </BoxPhoto>
                    <BoxContent>
                      <Date>
                        {formatDate(tournament.attributes.start_at)}
                      </Date>
                      <Title>{tournament.attributes.name}</Title>
                    </BoxContent>
                  </Row>
                  <Row>
                    <BoxTag>
                      {tournament.attributes.reward_pool && (
                        <TagText>
                          <span>{tournament.attributes.reward_pool} {tournament.attributes.reward_pool_currency || 'USDT'}</span> in reward pool
                        </TagText>
                      )}
                      {tournament.attributes.amount_start && (
                        <TagText>
                          Starting amount: <span>{tournament.attributes.amount_start}</span> USDT
                        </TagText>
                      )}
                      {tournament.attributes.leverage && (
                        <TagText>
                          Leverage: <span>{tournament.attributes.leverage.join(', ')}</span>
                        </TagText>
                      )}
                      {tournament.attributes.users_limit && (
                        <TagText>
                          Players limit: <span>{tournament.attributes.users_limit}</span>
                        </TagText>
                      )}
                    </BoxTag>
                  </Row>
                </ColLeft>
                <ColRight>
                  <ButtonFilled
                    className={tournament.attributes.status === 'finished' ? 'disabled' : ''}
                    disabled={tournament.attributes.status === 'finished'}
                  >
                    <Link
                      to={
                        tournament.attributes.status === 'finished'
                          ? RESULTS.replace(':id', tournament.id.toString())
                          : GAME.replace(':id', tournament.id.toString())
                      }
                    >
                      {tournament.attributes.status === 'finished' ? 'Results' : 'Join'}
                    </Link>
                  </ButtonFilled>
                </ColRight>
              </Box>
            </Li>
          ))}
          {tournaments.length === 0 && !loading && (
            <Li>
              <DetailInfo>No tournaments found</DetailInfo>
            </Li>
          )}
        </Ul>
      )}
    </Wrap>
  );
};

export default ListTournaments;

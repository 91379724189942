import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AuthenticationWrapper from 'components/layout/AuthenticationWrapper';
import { REGISTER, GAME, LANDING, FORGOT_PASSWORD, CREATING_TOURNAMENT } from 'constants/routes';
import { ButtonFilled } from 'components/ui/Button';
import { useUser } from 'api/userService';
import Cookies from 'js-cookie';
import { getCookieDomainFromUrl } from 'utils';
import { createTournament } from 'api/tournamentService';

import {
  Title,
  Text,
  IntroHead,
  StyledInput,
  BottomWrapper,
  ForgotPasswordLink,
} from './styled';

const domain = getCookieDomainFromUrl(process.env.REACT_APP_LANDING_PAGE);
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { login } = useUser();
  const intendedTournamentId = localStorage.getItem('intendToJoinTournament') ?? Cookies.get('intendToJoinTournament');
  const pendingTournamentData = localStorage.getItem('pendingTournamentData');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await login(email, password, async token => {        
        if (pendingTournamentData) {
          try {
            const parsedData = JSON.parse(pendingTournamentData);
            
            if (parsedData.action === 'join' && parsedData.tournamentId && parsedData.hash) {
              // Redirect to join the tournament with the specific ID and hash
              const baseUrl = process.env.REACT_APP_API_GAME_API || '';
              const joinUrl = `${baseUrl}/tournaments/${parsedData.tournamentId}/join?hash=${parsedData.hash}`;
              window.location.href = joinUrl;
              return;
            } else {
              // Handle regular tournament creation
              window.location.href = CREATING_TOURNAMENT;
            }
          } catch (parseError) {
            console.error('Error parsing pendingTournamentData:', parseError);
            // If parsing fails, default to tournament creation
            window.location.href = CREATING_TOURNAMENT;
          }
          // Clear the stored data              
          localStorage.removeItem('pendingTournamentData');
        } else if (intendedTournamentId) {
          localStorage.removeItem('intendToJoinTournament');
          Cookies.remove('intendToJoinTournament', { path: '/', domain });
          // Redirect to the specific tournament page
          window.location.href = `${GAME.replace(':id', intendedTournamentId)}`;
        } else {
          setTimeout(() => {
            window.location.href = LANDING;
          }, 250);
        }
      });
    } catch (error: unknown) {
      console.error('Login error:', error);
      if (typeof error === 'object' && error !== null && 'error' in error && typeof (error as { error: { message: string } }).error.message === 'string') {
        setError((error as { error: { message: string } }).error.message);
      } else {
        setError('An unexpected error occurred');
      }
    }
  };
  return (
    <AuthenticationWrapper>
      <IntroHead>
        <Title>Welcome back!</Title>
      </IntroHead>
      <form onSubmit={handleSubmit}>

        <StyledInput
          label="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <StyledInput
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          errorText={error}
        />

        <ForgotPasswordLink>
          <Link to={FORGOT_PASSWORD}>Forgot password?</Link>
        </ForgotPasswordLink>

        <BottomWrapper>
          <ButtonFilled type="submit" onClick={handleSubmit}>Log in</ButtonFilled>
          <Text>New here? <Link to={REGISTER}>Register now</Link></Text>
        </BottomWrapper>

      </form>
    </AuthenticationWrapper>
  );
};

export default Login;

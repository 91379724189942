import React, { forwardRef, useState } from 'react';
import IconSvg from 'components/ui/IconSvg';
import { ReactComponent as EyeSVG } from 'assets/icons/icon_eye.svg';
// eslint-disable-next-line import/no-cycle
import {
  InputWrapper,
  StyledInput,
  IconWrapper,
  IconClickableWrapper,
  Label,
  MessageWrapper,
  ErrorText,
} from './styled';

export interface InputProps {
  type?: string;
  name?: string;
  label?: string;
  value?: string | number;
  required?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorText?: string | boolean;
  success?: boolean;
  className?: string;
}

const PASSWORD_TYPE = 'password';
const TEXT_TYPE = 'text';

const Input = forwardRef(
  (props: InputProps, ref?: React.Ref<HTMLInputElement>) => {
    const {
      type = TEXT_TYPE,
      name,
      label,
      value,
      required,
      disabled,
      onChange,
      errorText,
      success,
      className,
    } = props;
    const [inputType, setInputType] = useState(type);

    const onShowToggle = () =>
      setInputType(inputType === PASSWORD_TYPE ? TEXT_TYPE : PASSWORD_TYPE);

    return (
      <InputWrapper className={className}>
        <StyledInput
          type={inputType}
          name={name}
          value={value}
          required={required}
          disabled={disabled}
          onChange={onChange}
          placeholder=" "
          password={type === PASSWORD_TYPE}
          errorText={errorText}
          success={success}
          ref={ref}
        />
        <Label htmlFor={name}>{label}</Label>
        <IconWrapper>
          {type === PASSWORD_TYPE && (
            <IconClickableWrapper onClick={onShowToggle}>
              <IconSvg icon={EyeSVG} />
            </IconClickableWrapper>
          )}
        </IconWrapper>
        <MessageWrapper>
          {errorText && <ErrorText>{errorText}</ErrorText>}
        </MessageWrapper>
      </InputWrapper>
    );
  },
);

export default Input;

import styled from 'styles/styled';
import bg from '../../../assets/PageDetail/bgGraphCut.png';

const ErrorContainer = styled.div`
  background-image: url(${bg});
  background-size: contain;
  background-position: center 100px;

  p {
    color: #fff;
    margin-bottom: 20px;
  }
  svg {
    margin-bottom: 20px;
  }
  .box {
    background-color: #15191E;
    padding: 40px 20px 20px 20px;
    border-radius: 8px;
    min-width: 400px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      width: 100%;
    }
  }
  .content {
    min-height: 100vh;
    min-height: 100svh;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export default ErrorContainer;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FullPageLoad from 'components/ui/FullPageLoad';
import { ButtonFilled } from 'components/ui/Button';
import BaseWrapper from 'components/layout/BaseWrapper';
import ListTournaments from 'components/CreatingTournament/ListTournaments';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { CREATING_TOURNAMENT } from 'constants/routes';
import Cookies from 'js-cookie';
import ModalProfil from 'components/Profil/ModalProfil';

import {
  Wrapper,
  ProfileWrap,
  BeltTop,
  Avatar,
  ColLeft,
  Btn,
  UserName,
  TextSmall,
  Box,
  IconEdit,
  Title,
  Number,
  Text,
  Legend,
  RowBelt,
  Tab,
  IconAdd,
  Content,
} from './styled';

const Profile = () => {
  const [isloadPage, setLoadPage] = useState<Promise<boolean | void> | boolean>(true);
  const username = localStorage.getItem('username') ?? Cookies.get('username');
  const useremail = localStorage.getItem('email') ?? Cookies.get('email');
  const [showModal, setShowModal] = useState(false)
  const handleClose = () => {setShowModal(false)}

  useEffect(() => {
    const timeout = setTimeout(() => setLoadPage(false), 1000)
    return () => clearTimeout(timeout);
  }, [])
  if (isloadPage) {
      return <FullPageLoad/>;
  };

  return (
    <BaseWrapper>
      <Wrapper>
        {/*
        <ProfileWrap>
          <BeltTop>
            <ColLeft>
              <Avatar />
              <Box>
                <UserName>{username}</UserName>
                <TextSmall>{useremail}</TextSmall>
              </Box>
            </ColLeft>
            <Btn onClick={() => setShowModal(true)}><IconEdit/>Edit profile</Btn>
          </BeltTop>


          <Carousel>
            <div>
              <Title>Number of tournaments<span>you played</span></Title>
              <Number>12</Number>
              <Text><span>80%</span> of all players played<br />more tournaments than you</Text>
              <Legend>Statistics</Legend>
            </div>
            <div>
              <Title>Average number of buy/sell transactions<span>you make per competition</span></Title>
              <Number>56</Number>
              <Text><span>29%</span> of players made more<br />transactions than you</Text>
              <Legend>Statistics</Legend>
            </div>
            <div>
              <Title>Your highest ranking position<span>in a single tournament</span></Title>
              <Number>11</Number>
              <Text>at <span>Bitwa Tradingowa Invest Cuffs</span></Text>
              <Legend>Statistics</Legend>
            </div>
            <div>
              <Title>Number of 1vs1 battles<span>you played</span></Title>
              <Number>111</Number>
              <Text><span>17%</span> of all players played<br />more 1vs1 battles than you</Text>
              <Legend>Statistics</Legend>
            </div>
          </Carousel>

          {showModal && <ModalProfil handleClose={handleClose} />}


        </ProfileWrap>
        */}

        <RowBelt>
          <Tab>Competitions</Tab>
          <Link to={CREATING_TOURNAMENT.replace(':id', '1')}>
            <ButtonFilled><IconAdd/> Create competition</ButtonFilled>
          </Link>
        </RowBelt>

        <Content>
          <ListTournaments />
        </Content>

      </Wrapper>
    </BaseWrapper>
  );
}
export default Profile;

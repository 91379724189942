import React, { ReactNode, useEffect, useState } from 'react';
import { logout } from 'api/authService';
import { LANDING } from 'constants/routes';
import { useUser } from 'api/userService';

import { ReactComponent as LogoSVGDesktop } from 'assets/Icon/logo.svg';
import { ReactComponent as LogoSVGMobile } from 'assets/Icon/mobile_logo.svg';
import IconSvg from 'components/ui/IconSvg';
import Avatar from 'components/ui/Avatar';
import BannerSponsor from 'components/ui/BannerSponsor';
import Menu from 'components/ui/Menu';
import Nav from 'components/ui/Nav';
import ListTournaments from 'components/CreatingTournament/ListTournaments';

import { useTournament } from 'context/TournamentContext';

import {
  Wrapper,
  NavTest,
  ColLeft,
  ColRight,
  Col,
  BtnLogo,
  BtnLogout,
  IconLogOut,
  PageContent,
  TournamentNull,
  WrapperPage,
  Title,
  SubTitle,
  BoxTop,
} from './styled';

interface Props {
  children: ReactNode;
}

const GameWrapper = ({ children }: Props) => {
  const { isLoggedIn } = useUser();
  const { tournamentDetails } = useTournament();
  const [isAnonymousUser, setAnonymousUser] = useState(false);
  const [isTournamentOpen, setTournamentOpen] = useState(true);
  const tournamentAnonymousUsers = tournamentDetails?.data?.attributes?.allow_anonymous_users;

  useEffect(() => {
    if (tournamentAnonymousUsers === false ) {
      setAnonymousUser(false);
    }
    if (tournamentAnonymousUsers === true ) {
      setAnonymousUser(true);
    }

    if (tournamentDetails === null ) {
      setAnonymousUser(true);
      setTournamentOpen(false);
    }
  });

  const handleLogoutClick = () => {
    logout();
  };
  let button;
  if (isLoggedIn) {
    button = <>Logout <IconLogOut></IconLogOut></>;
  } else {
    button = <>Log In <IconLogOut></IconLogOut></>;
  }
  return (
    <>

    <WrapperPage>
      <Nav
        showBanner
        showAvatar
      />


      {isTournamentOpen ? (
        <PageContent>
          {children}
        </PageContent>
      ) :
        <TournamentNull>
          <BoxTop>
            <Title>Upcoming Tournaments</Title>
            <SubTitle>Ready to win? Join trading tournaments!</SubTitle>
          </BoxTop>
          <ListTournaments />
        </TournamentNull>
      }

    </WrapperPage>

{/*
    <Wrapper>

      <NavTest>
        <ColLeft>
          <Col>
            <BtnLogo to={LANDING}>
              <IconSvg className="svgDesktop" width={140} icon={LogoSVGDesktop} />
              <IconSvg className="svgMobile"  width={65} icon={LogoSVGMobile} />
            </BtnLogo>
            {isLoggedIn &&<Avatar />}
          </Col>
          <Col className="center">
            <BannerSponsor />
          </Col>
          <Col className="menuCol">
            <div className="menuWrap">
              <Menu />
            </div>
            <BtnLogout className="btnLog" onClick={handleLogoutClick} to={LANDING}>
              {button}
            </BtnLogout>
          </Col>
        </ColLeft>
        {!isAnonymousUser ? (
          <ColRight>
            <BtnLogout onClick={handleLogoutClick} to={LANDING}>
              {button}
            </BtnLogout>
          </ColRight>
        ): null }
      </NavTest>

      <PageContent>

      </PageContent>

    </Wrapper>
      */}

    </>
  );
}

export default GameWrapper;

import styled from 'styles/styled';
import { StyledLink } from 'components/ui/StyledLink/StyledLink';
import imgLogout from '../../../assets/Icon/icon_logout.svg';
import imgAvatar from '../../../assets/icons/IconBull.svg';
import navDetail from '../../../assets/shadowNavMobile.svg';
import navDetailDesktop from '../../../assets/PageDetail/NavDetail.svg';

export const Wrap = styled.div`
  width: 100%;
  background-image: url(${navDetail});
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  box-shadow: inset 0px -10px 20px -10px #000;
  @media (max-width: 1020px;) {
    display: table;
  }
  @media (min-width: 1020px) {
    background: transparent;
  }
`;

export const Content = styled.div`
  padding-top: 12px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: calc(100% - 30px);
  margin: 0 auto;
`;

export const MobileWrap = styled.div`
  margin: 0 auto;
  @media (min-width: 1020px) {
    display: none;
  }
`;


export const BtnLogo = styled(StyledLink)`
  @media (max-width: 1300px) {
    .svgDesktop {
      svg {
        width: 100px;
      }
    }
  }
`;

export const BtnLogout = styled(StyledLink)`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #EFEFEF;
`;

export const IconLogOut = styled.div`
  background-image: url(${imgLogout});
  width: 13px;
  height: 17px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 7px;
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  max-width: calc(100% - 160px);
  &.avatarDesktop {
    margin-left: 35px;
    @media (min-width: 1020px) and (max-width: 1300px) {
      margin-left: 10px;
      max-width: 150px;
      margin-right: 8px;
    }
  }
`;

export const AvatarIcon = styled.div`
  width: 26px;
  height: 26px;
  background-image: url(${imgAvatar});
  background-size: cover;
  background-postition: center;
  background-repeat: no-repeat;
  border-radius: 100px;
  margin-right: 5px;
`;

export const AvatarName = styled.div`
  color: #EFEFEF;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (min-width: 1020px) and (max-width: 1300px) {
    font-size: 12px;
  }
`;

export const Banner = styled.div`
  width: 100%;
  height: 52px;
  border-radius: 14px;
  margin-bottom: 8px;
  position: relative;
  &.desktopBanner {
    width: 360px;
    margin-bottom: 4px;
    @media (min-width: 1020px) and (max-width: 1300px) {
      width: 260px;
      margin-bottom: -4px;
    }
  }
`;

export const InfoSponsor = styled.h5`
  position: absolute;
  right: 0;
  top: -8px;
  color: #EFEFEF;
  font-weight: 400;
  font-size: 6px;
  line-height: 8px;
  margin-right: 14px;
  @media (min-width: 1020px) {
    left: 0;
    right: inherit;
    margin: 0 0 0 14px;
  }
`;

export const DesktopWrap = styled.div`
  display: flex;
  max-width: calc(100% - 16px);
  width: 1440px;
  margin: 0 auto;
  @media (max-width: 1020px) {
    display: none;
  }
`;

export const ColLeft = styled.div`
  min-height: 66px;
  width: calc(100% - 290px);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-image: url(${navDetailDesktop});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  box-shadow: inset -30px 0px 30px -25px #000000;
`;

export const ColRight = styled.div`
  width: 282px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 66px;
`;

export const ContentDesktop = styled.div`
  display: flex;
  align-items: center;
  min-height: 66px;
`;

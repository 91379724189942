import React from 'react';
import { ReactComponent as LoadIntro } from 'assets/PageDetail/IntroSmall.svg';
import IconSvg from 'components/ui/IconSvg';

import {
  Wrap,
  LoadAnime,
} from './styled';

const FullPageLoad = () => {
  return (
    <Wrap>
      <IconSvg className="introPhoto" icon={LoadIntro} />
      <LoadAnime/>
    </Wrap>
  );
};

export default FullPageLoad;

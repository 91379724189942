import styled from 'styles/styled';
import imgClose from '../../../assets/icons/close_icon.svg';
import copyIconImg from '../../../assets/Icon/Copyicon.svg';
import iconShare from '../../../assets/Icon/Share.png';

export const Wrap = styled.div`
  margin-bottom: 8px;
  @media(max-width: 720px) {
    display: block;
  }
`;

export const ShareIcon = styled.div`
  background-position: center;
  background-image: url(${iconShare});
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    background-color: #292B2E;
    color: #EFEFEF;
    height: 32px;
    min-height: 32px;
    font-size: 14px;
    margin-left: 5px;
    &:hover {
      background-color: #3E4146;
    }
    @media(max-width: 1280px) {
      min-width: inherit;
    }
    @media(max-width: 720px) {
      line-height: 32px;
      width: calc(50% - 25px);
      margin: 0;
    }
    &.smallButton {
      min-width: inherit;
      width: inherit;
    }
  }
`;

export const AssetsModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  height: 100svh;
  background-color: rgba(0,0,0, 0.9);
  z-index: 200;
`;

export const AssetsWrap = styled.div`
  max-width: 1140px;
  width: calc(100% - 20px);
  margin: 29px auto;
  @media(max-width: 720px) {
    max-width: 400px;
  }
`;

export const AssetsScroll = styled.div`
  max-height: calc(100vh - 101px);
  max-height: calc(100svh - 101px);
  min-height: calc(100vh - 101px);
  min-height: calc(100svh - 101px);
  overflow-y: scroll;
`;

export const ButtonClose = styled.button`
  width: 40px;
  height: 40px;
  background-color: #454545;
  border-radius: 150px;
  color: #EFEFEF;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${imgClose});
`;

export const BeltTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3px;
`;

export const ShareModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  height: 100svh;
  background-color: rgba(0,0,0, 0.9);
  z-index: 200;
  display: flex;
  align-items: center;
`;

export const ShareWrap = styled.div`
  max-width: 540px;
  width: calc(100% - 20px);
  margin: 29px auto;
  @media(max-width: 720px) {
    max-width: 400px;
  }
`;

export const ContentShare = styled.div`
  background-color: #15191E;
  border-radius: 10px;
  padding: 40px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 56px;
`;

export const BoxQR = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const Text = styled.p`
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
  color: #EFEFEF;
`;

export const RowBelt = styled.div`
  margin-top: 12px;
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
  a {
    min-width: calc(50% - 4px);
    margin: 0 auto;
  }
`;

export const SubTitle = styled.h3`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #EFEFEF;
  @media (max-width: 720px) {
    font-size: 16px;
  }
`;

export const Btn = styled.a`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #52D381;
  padding: 8px 14px;
  background-color: #292B2E;
  border-radius: 20px;
  border: 1px solid #696969;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 100ms linear;
  &:hover {
    opacity: 0.8;
  }
`;

export const IconCopy = styled.span`
  background-image: url(${copyIconImg});
  width: 16px;
  height: 17px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 8px;
`;

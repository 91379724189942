import React, { useState, useContext } from 'react';
import { ButtonFilled } from 'components/ui/Button';
import AllAssets from 'components/Assets/AllAssets';
import MyAssets from 'components/Assets/MyAssets';
import { useTournament } from 'context/TournamentContext';
import SocketContext from 'utils/hooks/useSocket';
import { QRCodeSVG } from 'qrcode.react';

import {
  Wrap,
  ButtonWrap,
  AssetsModal,
  AssetsWrap,
  AssetsScroll,
  ButtonClose,
  BeltTop,
  ShareIcon,
  ShareModal,
  ShareWrap,
  ContentShare,
  Text,
  RowBelt,
  SubTitle,
  Btn,
  IconCopy,
  BoxQR,
} from './styled';


const WrapAsset = () => {
  const { tournamentPairList, tournamentDetails } = useTournament();
  const { setCoin } = useContext(SocketContext);
  const [isActiveMultiAssets, setIsActiveMultiAssets] = useState(false);
  const [isActiveAllAssets, setIsActiveAllAssets] = useState(false);
  const [isActiveMyAssets, setIsActiveMyAssets] = useState(false);
  const [isActiveShareModal, setIsActiveShareModal] = useState(false);

  const tournamentType = tournamentDetails?.data?.attributes?.trade_type;
  const tradingPairsList = tournamentPairList
  const [copied, setCopied] = useState(false);



  console.log(tournamentDetails);

  const [tournamentLink, setTournamentLink] = useState<string>(() => {
    const baseUrl = process.env.REACT_APP_API_GAME_API || '';
    return tournamentDetails.data.attributes.trade_type === 'margin'
      ? `${baseUrl}/game/${tournamentDetails.data.id}`
      : `${baseUrl}/game/${tournamentDetails.data.id}`;
  });

  const handleCopyLink = () => {
    if (tournamentLink) {
      navigator.clipboard.writeText(tournamentLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };
  
  const handleClickClose = event => {
    setIsActiveMultiAssets(false);
    setIsActiveAllAssets(false);
    setIsActiveMyAssets(false);
    setIsActiveShareModal(false);
  };

  const handleClickShare = () => {
    setIsActiveShareModal(true);
  };

  const handleClickAllAssets = event => {
    setIsActiveAllAssets(true);
    setIsActiveMultiAssets(true);
  };

  const handleClickMyAssets = event => {
    setIsActiveMyAssets(true);
    setIsActiveMultiAssets(true);
  };

  const handleClick = (data) => {
    setCoin(data)
    setIsActiveMultiAssets(false);
    setIsActiveAllAssets(false);
    setIsActiveMyAssets(false);
  };

  return (
    <Wrap>
      <ButtonWrap>
        {tradingPairsList.length > 0 && <ButtonFilled onClick={handleClickAllAssets}>All assets</ButtonFilled>}
        <ButtonFilled onClick={handleClickMyAssets}>My assets</ButtonFilled>

        {tradingPairsList.length > 0 &&
          <ButtonFilled onClick={handleClickShare} className="smallButton"><ShareIcon/></ButtonFilled>
        }

      </ButtonWrap>
      {isActiveMultiAssets &&
        <AssetsModal>
          <AssetsWrap>
            <BeltTop>
              <ButtonClose onClick={handleClickClose} />
            </BeltTop>
            <AssetsScroll>
              {isActiveAllAssets && <AllAssets pairsList = {tradingPairsList} onIconClick={handleClick}/> }
              {isActiveMyAssets && <MyAssets onIconClick={handleClick} tournamentID={tournamentDetails?.data?.id} tournamentType={tournamentType}/> }
            </AssetsScroll>
          </AssetsWrap>
        </AssetsModal>
      }

      {isActiveShareModal &&
        <ShareModal>
          <ShareWrap>
            <BeltTop>
              <ButtonClose onClick={handleClickClose} />
            </BeltTop>
            <ContentShare>
              <SubTitle>Share Tournament</SubTitle>
              <BoxQR>
                {tournamentLink && (
                  <div style={{
                    background: '#15191e',
                    padding: '20px',
                    borderRadius: '8px',
                    display: 'inline-block',
                    border: '1px solid #696969'
                  }}>
                    <QRCodeSVG
                      value={tournamentLink}
                      size={200}
                      level="H"
                      bgColor="#15191e"
                      fgColor="#52D381"
                      includeMargin={true}
                    />
                  </div>
                )}
              </BoxQR>

              <SubTitle>Dedicated link:</SubTitle>
              <Text>{tournamentLink}</Text>
              <RowBelt>
                <Btn onClick={handleCopyLink}>{!copied ? 'Copy link' : 'Copied!'}<IconCopy/></Btn>
              </RowBelt>
            </ContentShare>
          </ShareWrap>
        </ShareModal>
      }
    </Wrap>
  );
};

export default WrapAsset;

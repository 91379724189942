import React from 'react';
import SvgContainer from './styled';

interface Props {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  width?: number;
  height?: number;
  className?: string;
}

const IconSvg: React.FC<Props> = (props) => {
  const { icon: Icon } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgContainer {...props}>
      <Icon />
    </SvgContainer>
  );
};

export default IconSvg;

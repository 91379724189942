import React, { useState, useEffect } from 'react';
import FullPageLoad from 'components/ui/FullPageLoad';
import BaseWrapper from 'components/layout/BaseWrapper';
import { ButtonFilled } from 'components/ui/Button';
import TournamentDetails from 'components/CreatingTournament/TournamentDetails';
import TournamentLoad from 'components/CreatingTournament/TournamentLoad';
import TournamentCreating from 'components/CreatingTournament/TournamentCreating';
import { createTournament } from 'api/tournamentService';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import * as routes from 'constants/routes';

import {
  Wrapper,
  Article,
  Title,
  SubTitle,
  LoadAnime,
  SubTitleLarge,
  Row,
} from './styled';

interface TournamentData {
  tournament_type: '1vs1' | 'multiplayer';
  trading_assets: string;
  trade_type: 'Spot' | 'Margin';
  game_duration: '5 min' | '30 min' | '1h' | '12h' | '1d' | '7d';
  start_at: string;
}

interface TournamentResponse {
  data: {
    id: number;
    attributes: {
      status: string;
      start_at: string;
      end_at: string;
      is_public: boolean;
      name: string;
      hash: string;
      users_limit: number;
      tournament_type: '1vs1' | 'multiplayer';
    };
  };
}

const CreatingTournament = () => {
  const navigate = useNavigate();

  const [isloadPage, setLoadPage] = useState<Promise<boolean | void> | boolean>(true);
  const [isOpenedViewLoad, setIsOpenedViewLoad] = useState(false);
  const [isOpenedDetails, setIsOpenedDetails] = useState(false);
  const [isOpenedShareQR, setIsOpenedShareQR] = useState(false);
  const [isOpenedCreating, setIsOpenedCreating] = useState(false);
  const [tournamentData, setTournamentData] = useState<TournamentResponse['data'] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const pendingTournamentData = localStorage.getItem('pendingTournamentData');
    if (pendingTournamentData) {
      const createPendingTournament = async () => {
        try {
          // Create the tournament with the stored data
          const tournamentParams = JSON.parse(pendingTournamentData);
          console.log('Creating pending tournament:', tournamentParams);
          const response = await createTournament(tournamentParams);
          console.log('Tournament created:', response.data);

          // Clean up stored data
          localStorage.removeItem('pendingTournamentData');

          // Update state to show tournament details
          const tournamentDataWithType = {
            ...response.data,
            attributes: {
              ...response.data.attributes,
              tournament_type: tournamentParams.tournament_type
            }
          };

          setTournamentData(tournamentDataWithType);
          setIsOpenedViewLoad(false);
          setIsOpenedDetails(true);
          navigate(routes.CREATING_TOURNAMENT.replace(':id', response.data.id.toString()));
        } catch (err) {
          console.error('Failed to create pending tournament:', err);
          // setError(err.message || 'Failed to create tournament');
          setIsOpenedViewLoad(false);
        }
      };

      setIsOpenedViewLoad(true);
      createPendingTournament();
    }
  }, [navigate]);

  useEffect(() => {
    const timeout = setTimeout(() => setLoadPage(false), 1000)
    return () => clearTimeout(timeout);
  }, []);

  const handleTournamentCreate = async (data: TournamentData): Promise<{ data: { id: number } }> => {
    setIsOpenedViewLoad(true);
    setError(null);

    try {
      const response = await createTournament(data);
      const tournamentDataWithType = {
        ...response.data,
        attributes: {
          ...response.data.attributes,
          tournament_type: data.tournament_type
        }
      };

      setTournamentData(tournamentDataWithType);
      setIsOpenedViewLoad(false);
      setIsOpenedDetails(true);
      navigate(routes.CREATING_TOURNAMENT.replace(':id', response.data.id.toString()));
      return response;
    } catch (err) {
      console.error('Failed to create tournament:', err);
      setError(err.message || 'Only registered users can create multiplayer tournaments');
      setIsOpenedViewLoad(false);
      throw err;
    }
  };

  if (isloadPage) {
    return <FullPageLoad/>;
  }

  return (
    <BaseWrapper>
      <Wrapper>
        {!isOpenedCreating && !isOpenedViewLoad && !isOpenedDetails && (
          <Article className="centerItem">
            <TournamentCreating
              onCreateTournament={handleTournamentCreate}
              setIsOpenedViewLoad={setIsOpenedViewLoad}
              errorInfo={error}
            />
          </Article>
        )}

        {isOpenedViewLoad && (
          <TournamentLoad>
            <Title>Your game is being created</Title>
            <LoadAnime/>
            <SubTitle>Please wait...</SubTitle>
          </TournamentLoad>
        )}

        {isOpenedDetails && tournamentData && (
          <>
            <TournamentDetails tournamentData={tournamentData} />
          </>
        )}

        {isOpenedShareQR && (
          <TournamentLoad>
            <Row>
              <SubTitleLarge>Player: <span>CryptoJohn123</span></SubTitleLarge>
            </Row>
          </TournamentLoad>
        )}

        {error && (
          <div style={{ color: 'red', textAlign: 'center', marginTop: '1rem' }}>
            {error}
          </div>
        )}
      </Wrapper>
    </BaseWrapper>
  );
};

export default CreatingTournament;

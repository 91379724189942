import styled from 'styles/styled';

export const Wrap = styled.div`
  padding: 32px 12px 12px 12px;
`;

export const Ul = styled.ul`

`;

export const LoadWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 260px);
  min-height: calc(100svh - 260px);
`;

export const LoadAnime = styled.div`
  display: block;
  width: 52px;
  height: 52px;
  animation: rotation 800ms infinite linear;
  border-radius: 100%;
  margin-bottom: 10px;
  box-shadow: 1px 1px 0 1px #52d382;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const Tab = styled.div`
  background: #292B2E;
  border-radius: 14px;
  display: flex;
  margin-bottom: 16px;
  justify-content: space-around;
  align-items: center;
`;

export const ButtonTab = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #efefef;
  padding: 10px;
  border-radius: 14px;
  border: 1px solid transparent;
  width: 33%;
  text-align: center;
  cursor: pointer;
  &.active {
    border: 1px solid #FFFFFF;
    color: #52D381;
  }
`;

export const Li = styled.li`
  background-color: #696969;
  border-radius: 14px;
  margin-bottom: 4px;
  padding: 1px;
  &.is_your {
    background: #DBB230;
    h5 {
      display: block;
    }
  }
`;

export const Box = styled.div`
  background: #15191E;
  border-radius: 14px;
  min-height: 50px;
  padding: 8px 8px 2px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DetailInfo = styled.h5`
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  text-align: center;
  color: #15191E;
  padding: 3px 0;
  display: none;
  @media (min-width: 1020px) {
    font-size: 14px;
    padding: 4px 0;
  }
`;

export const ColLeft = styled.div`
  @media (min-width: 1020px) {
    display: flex;
  }
`;

export const TournamentPhoto = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  @media (min-width: 1020px) {
    width: 42px;
    height: 42px;
  }
`;

export const BoxStatus = styled.div`
  border-radius: 4px;
  border: 1px solid #696969;
  min-width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  @media (min-width: 1020px) {
    min-width: 42px;
    height: 42px;
  }
`;

export const StatusOnline = styled.h3`
  color: #EFEFEF;
  font-size: 9px;
  font-weight: 500;
  line-height: 10px;
  @media (min-width: 1020px) {
    font-size: 12px;
    line-height: 14px;
  }
  span {
    text-align: center;
    display: block;
    color: #52D381;
  }
`;

export const ColRight = styled.div`
  @media (min-width: 720px) {
    display: flex;
  }
  button {
    min-width: 80px;
    margin-bottom: 6px;
    &.disabled {
      background-color: #A7A8B2;
      cursor: pointer;
    }
    @media (min-width: 720px) {
      margin-left: 20px;
      min-width: 100px;
    }
    @media (max-width: 350px) {
      min-width: 55px;
    }
  }
`;

export const Info = styled.h3`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #EFEFEF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  @media (min-width: 1020px) {
    font-size: 16px;
  }
`;

export const TagText = styled.p`
  font-size: 8px;
  font-weight: 400;
  line-height: 8px;
  color: #EFEFEF;
  border-radius: 7px;
  border: 1px solid #696969;
  padding: 2px 13px;
  margin: 1px 0;
  @media (min-width: 1020px) {
    font-size: 12px;
    padding: 4px 13px;
  }
  @media (max-width: 350px) {
    padding: 2px 5px;
  }
  span {
    font-weight: 700;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  @media (min-width: 1020px) {
    margin-right: 20px;
  }
`;

export const BoxPhoto = styled.div`
  width: 32px;
  background: #353535;
  border-radius: 4px;
  margin-right: 8px;
  @media (min-width: 1020px) {
    width: 42px;
  }
`;

export const BoxContent = styled.div`

`;

export const BoxTag = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;


export const Date = styled.h4`
  font-size: 9px;
  font-weight: 700;
  line-height: 16px;
  color: #A0A0A0;
  @media (min-width: 1020px) {
    font-size: 12px;
  }
`;

export const Title = styled.h2`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #efefef;
  @media (min-width: 1020px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const Status = styled.p`
  font-size: 9px;
  font-weight: 500;
  line-height: 16px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1020px) {
    font-size: 11px;
  }
  &:before {
    content: " ";
    width: 4px;
    height: 4px;
    background-color: #FF4B4A;
    border-radius: 100px;
    margin-right: 3px;
  }
  &.active {
    &:before {
      background-color: #52D381;
    }
  }
`;

export const SmallText = styled.h4`
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  color: #efefef;
  padding: 0 13px;
  width: 100%;
  text-align: right;
  @media (min-width: 1020px) {
    font-size: 12px;
  }
`;

import React, { useState, useEffect } from 'react';
import { ButtonFilled } from 'components/ui/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ModalSmallInfoBase from 'components/ui/ModalSmallInfoBase';
import { PROFILE, REGISTER, HOME } from 'constants/routes';
import { useUser } from 'api/userService';
import { initializeSocket, socket } from 'api/socketService';
import Cookies from 'js-cookie';
import { QRCodeSVG } from 'qrcode.react';
import { readyTournament } from 'api/tournamentService';
import * as routes from 'constants/routes';
import TournamentLoad from 'components/CreatingTournament/TournamentLoad';
import BaseWrapper from 'components/layout/BaseWrapper';
import { SubTitleLarge, Wrapper } from 'screens/Authenticated/TournamentsUser/styled';
import { registerAnonymousUser } from 'utils/auth/anonymousRegistration';
import { logout } from 'api/authService';

import {
  SubTitle,
  WrapModal,
  Close,
  StyledInput,
  BoxQR,
  Wrap,
  BottomInfo,
  Title,
  Btn,
  IconCopy,
  Text,
  Row,
  RowBelt,
  Article,
  LoadAnime,
  Count,
} from './styled';

interface TournamentDetailsProps {
  tournamentData: {
    id: number;
    attributes: {
      status: string;
      start_at: string;
      end_at: string;
      is_public: boolean;
      name: string;
      hash: string;
      users_limit: number;
      tournament_type: string;
      allow_anonymous_users?: boolean;
    };
  };
}

const TournamentDetails: React.FC<TournamentDetailsProps> = ({ tournamentData }) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useUser();
  const { id } = useParams();
  const { isAuthenticated } = useUser();
  const [isOpenedModal, setIsOpenedModal] = useState(false);
  const [isOpenedModalStepOne, setIsOpenedModalStepOne] = useState(false);
  const [isOpenedModalStepTwo, setIsOpenedModalStepTwo] = useState(false);
  const [copied, setCopied] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [opponentUsername, setOpponentUsername] = useState<string | null>(null);
  const [isWaitingForOpponent, setIsWaitingForOpponent] = useState(false);
  const [showStartButton, setShowStartButton] = useState(false);
  const [isOpenedStartLoad, setIsOpenedStartLoad] = useState(false);
  const [seconds, setSeconds] = useState(11);

  const [tournamentLink, setTournamentLink] = useState<string>(() => {
    const baseUrl = process.env.REACT_APP_API_GAME_API || '';
    return tournamentData.attributes.tournament_type === 'multiplayer'
      ? `${baseUrl}/join/${tournamentData.id}?hash=${tournamentData.attributes.hash}`
      : `${baseUrl}/tournaments/${tournamentData.id}/join?hash=${tournamentData.attributes.hash}`;
  });

  useEffect(() => {
    const setupTournament = async () => {
      if (tournamentData.attributes.tournament_type === '1vs1') {
        const token = Cookies.get('jwt') || localStorage.getItem('jwt');

        if (socket && socket.connected) {
          const socketTournamentId = socket.io?.opts?.query?.tournamentId;

          if (socketTournamentId && socketTournamentId !== tournamentData.id.toString()) {
            socket.off('opponentJoined');
            socket.off('tournamentStarted');
            socket.disconnect();
          } else {
            handleConnect();
            return;
          }
        }
        initializeSocket(token, tournamentData.id.toString(), handleConnect);
      }
    };

    setupTournament();
  }, [tournamentData.id, navigate, tournamentData.attributes.tournament_type, isLoggedIn, tournamentData.attributes.allow_anonymous_users]);

  const handleConnect = () => {
    socket.on('opponentJoined', (username: string) => {
      setOpponentUsername(username);
      setShowStartButton(true);
      setIsWaitingForOpponent(false);
    });

    socket.on('tournamentStarted', () => {
      setIsWaitingForOpponent(false);
      setIsOpenedStartLoad(true);
      setSeconds(11);
      setTimeout(() => {
        window.onload = function () { window.location.reload() }
        navigate(routes.GAME.replace(':id', tournamentData.id.toString()));
        window.location.reload();
      }, 10000);
    });
  };

  const handleProfile = async () => {
    navigate(PROFILE);
  };

  const handleCopyLink = () => {
    if (tournamentLink) {
      navigator.clipboard.writeText(tournamentLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const handleStart = async () => {
    try {
      if (!tournamentData?.id) return;

      setIsWaitingForOpponent(true);
      await readyTournament(tournamentData.id.toString());
    } catch (err) {
      setIsWaitingForOpponent(false);
    }
  };

  const showPopUp = () => {
    setIsOpenedModal(true);
    setIsOpenedModalStepOne(false);
    setIsOpenedModalStepTwo(false);
  };

  const closePopUp = () => {
    setIsOpenedModal(false);
  };

  const handleSubmit = async () => {
    setError(null);
    try {
      setIsOpenedModalStepOne(true);
      setIsOpenedModalStepTwo(true);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    const secondInterval = setInterval(() => {
      setSeconds(prev => (prev - 1 < 0 ? 59 : prev - 1));
    }, 1000);
    return () => {
      clearInterval(secondInterval);
    };
  }, []);

  return (
    <>
      {!opponentUsername && !isWaitingForOpponent && (
        <Article>
          <Wrap>

            <Title>
              {tournamentData.attributes.tournament_type === 'multiplayer'
                ? 'Multiplayer tournament successfully created!'
                : '1vs1 battle successfully created!'}
            </Title>

            {tournamentData.attributes.tournament_type === '1vs1' && !opponentUsername && !isWaitingForOpponent && (
              <>
                <Text>
                  Once your opponent joins via QR code or link below, you'll go straight to the game
                </Text>

                <BoxQR>
                  {tournamentLink && (
                    <div style={{
                      background: '#15191e',
                      padding: '20px',
                      borderRadius: '8px',
                      display: 'inline-block',
                      border: '1px solid #696969'
                    }}>
                      <QRCodeSVG
                        value={tournamentLink}
                        size={200}
                        level="H"
                        bgColor="#15191e"
                        fgColor="#52D381"
                        includeMargin={true}
                      />
                    </div>
                  )}
                </BoxQR>

                {/*
              <Row>
                <Btn onClick={handleCopyLink}>Copy QR code<IconCopy/></Btn>
              </Row>
              */}
              </>
            )}

            {tournamentData.attributes.tournament_type === 'multiplayer' && (
              <>
                <Text>
                  Share this tournament with your friends using the QR code or link below
                </Text>

                <BoxQR>
                  {tournamentLink && (
                    <div style={{
                      background: '#15191e',
                      padding: '20px',
                      borderRadius: '8px',
                      display: 'inline-block',
                      border: '1px solid #696969'
                    }}>
                      <QRCodeSVG
                        value={tournamentLink}
                        size={200}
                        level="H"
                        bgColor="#15191e"
                        fgColor="#52D381"
                        includeMargin={true}
                      />
                    </div>
                  )}
                </BoxQR>

                {/*
              <Row>
                <Btn onClick={handleCopyLink}>Copy QR code<IconCopy/></Btn>
              </Row>
              */}
              </>
            )}

            <BottomInfo>
              <SubTitle>Dedicated link:</SubTitle>
              <Text>{tournamentLink}</Text>
              <RowBelt>
                <Btn onClick={handleCopyLink}>{!copied ? 'Copy link' : 'Copied!'}<IconCopy /></Btn>
              </RowBelt>
              <RowBelt>
                {tournamentData.attributes.tournament_type === 'multiplayer' ? (
                  <>
                    <ButtonFilled
                      onClick={() => window.open(tournamentLink, '_blank')}
                    >Start
                    </ButtonFilled>
                  </>
                ) : (
                  <></>
                )}
                {/*<Btn onClick={showPopUp}>Share link<IconCopy className="shareIcon"/></Btn>*/}
              </RowBelt>
            </BottomInfo>

            {/*
            {isLoggedIn && <ButtonFilled onClick={handleProfile}>Go to Profile</ButtonFilled>}
          */}

            {isOpenedModal && (
              <ModalSmallInfoBase>
                <Close onClick={closePopUp} />
                <WrapModal>
                  {!isOpenedModalStepOne ? (
                    <>
                      <SubTitle>Share via e-mail</SubTitle>
                      <StyledInput
                        label="E-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        errorText={error}
                      />
                      <ButtonFilled onClick={handleSubmit}>Send</ButtonFilled>
                    </>
                  ) : (
                    <>
                      <SubTitle>E-mail sent!</SubTitle>
                      <ButtonFilled onClick={closePopUp}>Close</ButtonFilled>
                    </>
                  )}
                </WrapModal>
              </ModalSmallInfoBase>
            )}
          </Wrap>
        </Article>
      )}

      {!isOpenedStartLoad && (
        <>
          {tournamentData.attributes.tournament_type === '1vs1' && opponentUsername && !isWaitingForOpponent && (
            <TournamentLoad>
              <Title>Opponent joined!</Title>
              <SubTitle className="center">
                Your opponent <span>{opponentUsername}</span><br />has joined the battle.
              </SubTitle>
              <ButtonFilled onClick={handleStart}>Start battle</ButtonFilled>
            </TournamentLoad>
          )}

          {tournamentData.attributes.tournament_type === '1vs1' && isWaitingForOpponent && (
            <TournamentLoad>
              <Title>Waiting for opponent...</Title>
              <Text>Please wait while your opponent joins the battle.</Text>
            </TournamentLoad>
          )}
        </>
      )}

      {isOpenedStartLoad && (
        <TournamentLoad>
          <Title>The battle starts in</Title>

          {(seconds < 11 || seconds === 0) ? (
            <Count>
              {seconds}
            </Count>
          ) :
            <LoadAnime />
          }

        </TournamentLoad>
      )}

    </>
  );
};

export default TournamentDetails;

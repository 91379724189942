import styled from 'styles/styled';

export const Box = styled.div`
  background-color: #15191E;
  border-radius: 10px;
  width: 100%;
  padding: 13px 0;
  @media (max-width: 720px) {
    min-width: 225px;
    max-width: 460px;
    padding: 8px 0;
    height: 238px;
    bottom: 8px;
    left: 0;
    right: 0;
    z-index: 20;
    width: calc(100% - 90px);
    position: relative;
    margin: 0 auto;
    min-height: initial;
  }
  @media (max-height: 500px) {
    position: relative;
  }
  div {
    color: #EFEFEF;
  }
`;

export const Wrap = styled.div`
  max-width: 1150px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 720px) {
    display: block;
  }
`;

export const Col = styled.div`
  max-width: 390px;
  width: 100%;
  button {
    width: 100%;
    min-height: 36px;
    @media (max-width: 720px) {
      min-height: 30px;
      border-radius: 8px;
    }
    &.SellDetails {
      background-color: #FF4B4A;
    }
    &:disabled {
      background-color: #292B2E;
      color: #000;
    }
  }
  @media (max-width: 1330px) {
    max-width: 45%;
  }
  @media (max-width: 720px) {
    display: none;
    max-width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;
  }
`;

export const Tab = styled.div`
  display: none;
  background-color: #292B2E;
  height: 38px;
  border-radius: 12px;
  margin: 0 10px;
  position: absolute;
  top: 35px;
  width: calc(100% - 20px);
  z-index: 10;
  @media (max-width: 720px) {
    display: block;
    height: 30px;
    border-radius: 8px;
  }
`;

export const Button = styled.button`
  font-size: 16px;
  cursor: pointer;
  width: 50%;
  text-align: center;
  height: 38px;
  font-weight: 600;
  color: #A0A0A0;
  background-color: transparent;
  position: relative;
  @media (max-width: 720px) {
    font-size: 12px;
    height: 30px;
    border-radius: 8px;
  }
  &:nth-child(2) {
    &.active {
      background-color: #FF4B4A;
      color: #0A0A0A;
      &:after {
        content: " ";
        background: #292b2e;
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: 19px solid transparent;
        border-bottom: 19px solid transparent;
        border-right: 19px solid #FF4B4A;
        @media (max-width: 720px) {
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          border-right: 13px solid #FF4B4A;
        }
      }
    }
  }
  &:nth-child(1) {
    &.active {
      background-color: #52D381;
      color: #0A0A0A;
      &:after {
        content: " ";
        background: #292b2e;
        position: absolute;
        right: 0;
        top: 0;
        border-top: 19px solid transparent;
        border-bottom: 19px solid transparent;
        border-left: 19px solid #52D381;
        @media (max-width: 720px) {
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          border-left: 13px solid #52D381;
        }
      }
    }
  }
`;

export const MobileView = styled.div`
  display: none;
  max-width: 430px;
  width: 100%;
  position: relative;
  button {
    width: 100%;
    min-height: 45px;
    @media (max-width: 720px) {
      min-height: 30px;
      border-radius: 8px;
      height: 30px;
      font-size: 14px;
    }
    &.SellDetails {
      background-color: #FF4B4A;
    }
    &:disabled {
      background-color: #292B2E;
      color: #000;
    }
  }
  @media (max-width: 720px) {
    display: block;
    max-width: 100%;
    padding: 0 10px;
  }
`;

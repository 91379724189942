import styled from 'styles/styled';

export const Wrapper = styled.div`

`;

export const ColLeft = styled.div`
  width: calc(100% - 290px);
  @media (max-width: 1020px) {
    width: 100%;
  }
`;

export const ColRight = styled.div`
  width: 282px;
  @media (max-width: 1020px) {
    display: none;
  }
`;

export const Box = styled.div`
  background-color: #15191E;
  width: 100%;
  border-radius: 10px;
  min-height: 600px;
  margin-bottom: 8px;
  height: 100vh;
  max-height: calc(100vh - 65px - 8px);
  max-height: calc(100svh - 65px - 8px);
  @media (max-width: 1020px) {
    max-height: calc(100vh - 90px - 8px);
    max-height: calc(100svh - 90px - 8px);
  }
  @media (min-height: 1000px) {
    max-height: calc(100vh - 282px);
    max-height: calc(100svh - 282px);
  }
  @media (min-height: 1600px) {
    max-height: calc(70vh - 50px - 8px);
    max-height: calc(70svh - 50px - 8px);
  }
  @media (max-width: 720px) {
    min-height: 500px;
  }
  &.boxDetails {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 720px) {
      max-height: calc(100vh - 60px);
      max-height: calc(100svh - 60px);
    }
    @media (max-width: 1020px) {
      @media (max-height: 570px) {
        max-height: 100%;
        height: 100%;
      }
    }
  }
`;

export const RowTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const RwdContent = styled.div`
  @media (max-width: 720px) {
    border-radius: 8px;
    background-color: #15191E;
    padding: 8px 10px;
  }
  @media (max-width: 1020px) {
    border-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const TitleMobile = styled.h4`
  display: none;
  @media (max-width: 720px) {
    display: block;
    font-size: 14px;
    color: #848484;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 8px;
  }
`;

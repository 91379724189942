import styled from 'styles/styled';
import { StyledLink } from 'components/ui/StyledLink/StyledLink';

export const TabBtnMenu = styled(StyledLink)`
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  color: #EFEFEF;
  min-width: 124px;
  background-color: #15191E;
  margin-left: 10px;
  text-align: center;
  padding: 2px 10px 2px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: block;
  @media (max-width: 1020px) {
    width: calc(50% - 5px);
    margin: 0;
  }
  @media (min-width: 1020px) and (max-width: 1300px) {
    font-size: 12px;
    padding: 2px 5px 2px 5px;
    min-width: 100px;
    margin-left: 8px;
  }
  &:hover {
    opacity: 1;
    color: ${({ theme }) => theme.palette.primary};
  }
  &.Disabled {
    color: #454545;
    background-color: #090B0E;
    pointer-events: none;
  }
  &.Active {
    color: ${({ theme }) => theme.palette.primary};
    background-color: #15191E;
    &:hover {
      color: #EFEFEF;
    }
  }
`;

export const MenuBottom = styled.div`
  margin-right: 24px;
  display: flex;
  @media (min-width: 1020px) and (max-width: 1300px) {
    margin: 0 15px 0 8px;
  }
  @media (max-width: 1020px) {
    margin: 0;
    justify-content: space-between;
  }
`;

import React, { ReactNode, useEffect, useState } from 'react';
import { logout } from 'api/authService';
import { LANDING } from 'constants/routes';
import { useUser } from 'api/userService';

import { ReactComponent as LogoSVGDesktop } from 'assets/Icon/logo.svg';
import { ReactComponent as LogoSVGMobile } from 'assets/Icon/mobile_logo.svg';
import IconSvg from 'components/ui/IconSvg';
import Avatar from 'components/ui/Avatar';
import Menu from 'components/ui/Menu';
import { useTournament } from 'context/TournamentContext';

import {
  Wrapper,
  Nav,
  ColLeft,
  ColRight,
  Col,
  BtnLogo,
  BtnLogout,
  IconLogOut,
  PageContent,
  MenuMobile,
} from './styled';

interface Props {
  children: ReactNode;
}

const BaseWrapper = ({ children }: Props) => {
  const { isLoggedIn } = useUser();
  const { tournamentDetails } = useTournament();
  const [isAnonymousUser, setAnonymousUser] = useState(false);
  const tournamentAnonymousUsers = tournamentDetails?.data?.attributes?.allow_anonymous_users;

  useEffect(() => {
    if (tournamentAnonymousUsers === false ) {
      setAnonymousUser(false);
    }
    if (tournamentAnonymousUsers === true ) {
      setAnonymousUser(true);
    }
  });

  const handleLogoutClick = () => {
    logout();
  };
  let button;
  if (isLoggedIn) {
    button = <>Logout <IconLogOut></IconLogOut></>;
  } else {
    button = <>Log In <IconLogOut></IconLogOut></>;
  }
  return (
    <Wrapper>
      <Nav>
        <ColLeft>
          <Col>
            <BtnLogo to={LANDING}>
              <IconSvg className="svgDesktop" width={140} icon={LogoSVGDesktop} />
              <IconSvg className="svgMobile"  width={65} icon={LogoSVGMobile} />
            </BtnLogo>
            {isLoggedIn &&<Avatar />}
          </Col>
          <Col className="center">

          </Col>
          <Col className="menuCol">
            <div className="menuWrap">
              <Menu />
            </div>
            <BtnLogout className="btnLog" onClick={handleLogoutClick} to={LANDING}>
              {button}
            </BtnLogout>
          </Col>
        </ColLeft>
        <ColRight>
          <BtnLogout onClick={handleLogoutClick} to={LANDING}>
            {button}
          </BtnLogout>
        </ColRight>
      </Nav>
      <MenuMobile>
        <Menu />
      </MenuMobile>
      <PageContent>
        <>{children}</>
      </PageContent>
    </Wrapper>
  );
}

export default BaseWrapper;

import styled from 'styles/styled';

export const Box = styled.div`
  background-color: #15191E;
  border-radius: 10px;
  padding: 13px 0;
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  display: flex;
  height: 100%;
  @media (max-width: 720px) {
    height: initial;
    padding: 0;
    min-height: initial;
    display: block;
    margin-bottom: 0;
    @media (max-height: 560px) {
      min-height: inherit;
    }
  }
`;

export const Wrap = styled.div`
  padding: 0 14px;
  width: 100%;
  position: relative;
  @media (max-width: 720px) {
    padding: 0;
  }
  .hide_mobile {
    display: none;
  }
`;

export const Currency = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  padding-right: 8px;
  margin-right: 8px;
  color: #EFEFEF;
  border-right: 1px solid #EFEFEF;
  @media (max-width: 1000px) {
    padding-right: 10px;
    margin-right: 10px;
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: 720px) {
    font-size: 10px;
    line-height: 20px;
    border: none;
    position: absolute;
    background-color: #292B2E;
    top: 118px;
    left: 5px;
    padding: 4px 7px;
    color: #989898;
    z-index: 10;
    border-radius: 8px;
  }
  &.hideDesktop {
    display: none;
    @media (max-width: 720px) {
      display: block;
    }
  }
`;

export const Amount = styled.h4`
  font-size: 16px;
  line-height: 15px;
  color: #52D381;
  margin-right: 16px;
  @media (max-width: 1000px) {
    font-size: 16px;
    min-width: 75px;
  }
  @media (max-width: 720px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Title = styled.h6`
  font-size: 10px;
  color: #696969;
  font-weight: 700;
`;

export const Text = styled.p`
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  color: #EFEFEF;
  &.up_state {
    color: #52D381;
  }
  &.down_state {
    color: #FF4B4A;
  }
  span {
    color: #EFEFEF;
  }
`;

export const InfoBlock = styled.div`
  margin-right: 15px;
  @media (max-width: 720px) {
    display: none;
  }
`;

export const BeltInformationDesktop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  @media (max-width: 720px) {
    display: none;
  }
`;

export const GraphWrapper = styled.div`
  width: 100%;
  border: 1px solid #515152;

  min-height: 240px;
  height: calc(100vh - 433px);
  height: calc(100svh - 433px);
  @media (min-height: 1000px) {
    height: calc(100vh - 642px);
  }
  @media (min-height: 1600px) {
    height: calc(100vh - 943px);
    max-height: calc(70svh - 411px - 8px);
  }
  @media (max-width: 1020px) {
    height: calc(100vh - 455px);
    height: calc(100svh - 455px);
  }
  &.Spot {
    @media (max-width: 1020px) {
      height: calc(100vh - 469px);
      height: calc(100svh - 469px);
    }
  }
  &.Margin {
    min-height: 167px;
    height: calc(100vh - 506px);
    height: calc(100svh - 506px);
    @media (min-height: 1000px) {
      height: calc(100vh - 715px);
    }
    @media (min-height: 1600px) {
      height: calc(100vh - 1015px);
      max-height: calc(70svh - 483px - 8px);
    }
    @media (max-width: 1020px) {
      max-height: calc(100vh - 526px);
      max-height: calc(100svh - 526px);
      height: calc(100vh - 528px);

      @media (max-height: 570px) {
      min-height: 138px;
      min-height: calc(100vh - 300px);
      min-height: calc(100svh - 300px);
      height: calc(100vh - 484px);
      height: calc(100svh - 484px);
      }
    }
    @media (max-width: 720px) {
      min-height: 138px;
      min-height: calc(100vh - 526px);
      min-height: calc(100svh - 526px);
      height: calc(100vh - 524px);
      height: calc(100svh - 524px);
    }
    @media (max-width: 500px) {
      min-height: calc(100vh - 490px);
      min-height: calc(100svh - 490px);
    }
    @media (max-width: 1020px) {
      @media (max-height: 570px) {
        min-height: 215px;
      }
    }
    @media (max-width: 720px) {
      @media (max-height: 570px) {
        min-height: 160px;
      }
    }
  }
`;

export const BeltInformationMobile = styled.div`
  border: 1px solid #848484;
  background-color: transparent;
  display: none;
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 5px 10px;
  justify-content: space-between;
  @media (max-width: 720px) {
    display: flex;
  }
`;

export const InfoBox = styled.div``;

export const Info = styled.h4`
  font-size: 12px;
  font-weight: 400;
  color: #EFEFEF;
  span {
    &.up_state {
      color: #52D381;
    }
    &.down_state {
      color: #FF4B4A;
    }
  }
`;

export const TimeBox = styled.div`
  .timeText {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #EFEFEF !important;
    background-color: #000;
    min-height: inherit;
    line-height: initial;
    text-align: left;
    width: initial;
  }
`;

export const TitleSmall = styled.h5`
  font-size: 10px;
  font-weight: 400;
  color: #848484;
  margin-bottom: 3px;
  color: #EFEFEF;
`;

export const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export const LoadWrap = styled.div`
  display: block;
  width: 15px;
  height: 15px;
  animation: rotation 800ms infinite linear;
  border-radius: 100%;
  box-shadow: 0.6px 0.5px 0 0.6px #52D381;
  margin-right: 5px;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
